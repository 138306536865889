import "./App.css";

import logo from "./img/logo.svg";

function App() {
  return (
    <div className="App">
      <div className="content">
        <header>
          <div className="logo">
            <img src={logo} alt="Логотип" />
            <span>Delivery Points</span>
          </div>
          <ul className="menu">
            <li>Преимущества</li>
            <li>Виды доставки</li>
            <li>Этапы работы</li>
            <li>Тарифы</li>
          </ul>
          <div className="head_btn">
            <p>Заказать доставку</p>
            <p>Стать курьером</p>
          </div>
        </header>
        <main>
          <div className="main__left-content">
            <h1>
              Надежный сервис <br />
              доставки товаров
            </h1>
            <p>
              Предлагаем выгодные транспортные решения <br /> и доставку в
              кратчайшие сроки.
            </p>
            <button className="btn">Заказать доставку</button>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
